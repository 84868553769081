.ivm-page-content {
  .card,
  .card-header,
  .p-card-body {
    background: #1f2430 !important;
  }
  .secondary-card {
    background-color: #0b101b !important;
    padding: 10px;
  }
  .p-inputtext,
  .p-dropdown,
  .p-dropdown-panel,
  .dzu-dropzone,
  .MuiOutlinedInput-root,
  input {
    background-color: #242a30 !important;
  }
  .progress-nav .nav .nav-link.done {
    background-color: #369eda;
  }
  .text-heading {
    color: #369eda;
  }
}
.p-dropdown-panel {
  background: #242a30 !important;
  input {
    background: #242a30 !important;
  }
}
.p-inputtext-label {
  color: #fff;
}

.text-colors {
  display: inline-block;
  background: linear-gradient(to right, #00cfe5, #c471ed, #f64f59);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.membership-image {
  width: 160px;
}
