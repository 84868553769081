//
// backgrounds.scss
//

@each $color, $value in $theme-colors {
  .bg-soft-#{$color} {
    background-color: rgb(8 7 26) !important;
  }
}

.bg-soft-dark {
  background-color: rgba(var(--#{$variable-prefix}dark-rgb), 0.18) !important;
}

.bg-soft-light {
  background-color: rgba(var(--#{$variable-prefix}light-rgb), 0.18) !important;
}
