@import 'bootstrap/functions';
@import 'bootstrap/variables';

/*============================
		COLOR Red
==============================*/

$primary-color: #9e0334;
$primary-color-hover: darken($primary-color, 7%);

::selection {
  background: $primary-color;
}

a,
a:focus {
  color: $primary-color;
}
a:hover,
a:active {
  color: $primary-color-hover;
}

.primary-menu {
  ul.navbar-nav > li {
    &:hover > a:not(.btn),
    & > a.active:not(.btn) {
      color: $primary-color;
    }
    &.dropdown {
      .dropdown-menu li {
        &:hover > a:not(.btn) {
          color: $primary-color;
        }
      }
    }
  }
  &.navbar-line-under-text ul.navbar-nav > li {
    > a:not(.btn):after {
      border-color: $primary-color;
    }
  }
}

/* Accordion & Toggle */
.accordion {
  &:not(.accordion-alternate) .card-header a {
    background-color: $primary-color;
    color: #fff;
  }
}

/* Nav */
.nav:not(.nav-pills) .nav-item .nav-link.active,
.nav:not(.nav-pills) .nav-item .nav-link:hover {
  color: $primary-color;
}
.nav-tabs {
  .nav-item .nav-link {
    &.active {
      &:after {
        background-color: $primary-color;
      }
      color: #0c2f55;
    }
    &:not(.active):hover {
      color: $primary-color;
    }
  }
  &.flex-column {
    .nav-item {
      .nav-link.active {
        color: $primary-color;
      }
    }
  }
}
.nav-pills .nav-link:not(.active):hover {
  color: $primary-color;
}

#footer {
  .nav {
    .nav-item {
      .nav-link {
        &:focus {
          color: $primary-color;
        }
      }
    }
    .nav-link:hover {
      color: $primary-color;
    }
  }
  .footer-copyright {
    .nav {
      .nav-link:hover {
        color: $primary-color;
      }
    }
  }
}

/* Extras */
.bg-primary,
.badge-primary {
  background-color: $primary-color !important;
}
.text-primary,
.btn-light,
.btn-outline-light:hover,
.btn-link,
.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active {
  color: $primary-color !important;
}
.btn-link:hover {
  color: $primary-color-hover !important;
}

.text-muted {
  color: #8e9a9d !important;
}
.text-light {
  color: #dee3e4 !important;
}

a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color: $primary-color-hover !important;
}
.border-primary {
  border-color: $primary-color !important;
}

.btn-primary {
  background-color: $primary-color;
  border-color: $primary-color;
  &:hover {
    background-color: $primary-color-hover;
    border-color: $primary-color-hover;
  }
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active {
  background-color: $primary-color-hover;
  border-color: $primary-color-hover;
}

.btn-primary.focus,
.btn-primary:focus {
  background-color: $primary-color-hover;
  border-color: $primary-color-hover;
}

.btn-outline-primary,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active {
  color: $primary-color;
  border-color: $primary-color;
  &:hover {
    background-color: $primary-color;
    border-color: $primary-color;
    color: #fff;
  }
}

.progress-bar,
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link,
.dropdown-item.active,
.dropdown-item:active {
  background-color: $primary-color;
}
.page-item.active .page-link,
.custom-radio .custom-control-input:checked ~ .custom-control-label:before,
.custom-control-input:checked ~ .custom-control-label::before,
.custom-checkbox .custom-control-input:checked ~ .custom-control-label:before,
.custom-control-input:checked ~ .custom-control-label:before {
  background-color: $primary-color;
  border-color: $primary-color;
}
.list-group-item.active {
  background-color: $primary-color;
  border-color: $primary-color;
}
.page-link {
  color: $primary-color;
  &:hover {
    color: $primary-color-hover;
  }
}

.image-container {
  button {
    width: 100%;
    border: none;
  }
  .image {
    width: 100%;
  }
}
.subscribe-wrapper {
  margin-top: 80px;
}
.nnn-large {
  width: 280px;
  margin: auto;
}
.w-full {
  width: 100%;
}
.w-50 {
  width: 50%;
}
.w-38 {
  width: 38%;
}
.w-80 {
  width: 80%;
}
.w-85 {
  width: 85%;
}

.w-43 {
  width: 43%;
}
.w-30 {
  width: 30%;
}
.item-center {
  align-items: center;
}
.w-180 {
  width: 180px;
}
.w-132 {
  width: 132px;
}
.font-size-16 {
  font-size: 16px;
}
.px-80 {
  padding: 0 80px;
}
#about,
#notify,
#individual,
#organization {
  .modal-content {
    background: #121418;
  }
}
.product-item:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
  transition: box-shadow 0.3s ease;
}
@media screen and (max-width: 769px) {
  .map-isp {
    height: 910px !important;
  }
}
